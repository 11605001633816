import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import AuctionCard from '../components/AuctionCard'; 
import './AuctionsPage.css'; 
import { ENDPOINTS } from '../config/endpoints';
import { v4 as uuidv4, validate } from 'uuid';
import { isAdmin } from '../services/isAdmin';

const AllAuctionsList = () => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const admin = isAdmin();

    // Función para obtener las auctions (puedes alternar entre real y hardcoded)
    const fetchAuctions = async () => {
        try {
            const response = await axios.get(ENDPOINTS.API.REQUESTS_BY_ADMIN(user),{
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log('Auctions:', response.data);
            setAuctions(response.data);
        } catch (error) {
            console.error('Error fetching auctions:', error);
        } finally {
            setLoading(false);
        }

        // Versión hardcoded para pruebas
        setLoading(false);
    };

    useEffect(() => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        let user = null;
        
        for (const key of keys) {
          if (key.includes('idToken')) {
            idToken = localStorage.getItem(key);
          }
          if (key.includes('LastAuthUser')) {
            user = localStorage.getItem(key);
          }
        }
      
        if (idToken) {
          setToken(idToken);
          setUser(user);
        } else {
          console.error('No tokens found in localStorage');
        }
      }, []);

    // Llamar a fetchAuctions al montar el componente
    useEffect(() => {
        if (user) {
           fetchAuctions(); 
        }
    }, [user]);

    const publish_auction = async () => {
        try{
            const auction_pusblish = await axios.post(`${ENDPOINTS.API.OFFER_AUCTION}`, auctions[0]);
        }
        catch(error){
            console.log(error)
        }
    }

    // Mostrar un mensaje de carga mientras se obtienen los datos
    if (loading) {
        return <p>Loading auctions...</p>;
    }

    // Renderizar las auctions
    return (
        <Container>
            {admin ? ( // Verifica si el usuario es administrador
                <>
                    <Row>
                        <Col></Col>
                        <Col xs={8}>
                            {auctions.length > 0 ? (
                                auctions.map((auction) => (
                                    <div key={auction.auction_id} className="auction-card">
                                        <AuctionCard auction={auction} admin={admin}/>
                                    </div>
                                ))
                            ) : (
                                <p>No auctions found.</p>
                            )}
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className="mt-4">
                    </Row>
                </>
            ) : (
                <p>Not Authorized</p> // Muestra esto si el usuario no es administrador
            )}
        </Container>
    );
    
};

// Componente principal para la página de Auctions
const AllAuctionsPage = () => {
    return (
        <div>
            <h1>Bonds to offer</h1>
            <AllAuctionsList />
        </div>
    );
};

export default AllAuctionsPage;
