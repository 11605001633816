import React, { useState, useEffect } from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import axios from 'axios';
import AuctionCard from './AuctionCard';
import ProposalCard from './ProposalCard';
import { ENDPOINTS } from '../config/endpoints';

const BondsModal = ({ show, handleClose, user, token, fixture, admin }) => {
    const [loading, setLoading] = useState(true);
    const [bonds, setBonds] = useState([]);

    useEffect(() => {
        if (show) {
            fetchBonds();
        }
    }, [show]);

    const fetchBonds = async () => {
        try {
            const response = await axios.get(ENDPOINTS.API.REQUESTS_BY_ADMIN(user),{
                headers: { Authorization: `Bearer ${token}` },
            });
            setBonds(response.data);
            console.log('Bonds:', response.data);
        } catch (error) {
            console.error('Error fetching bonds:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <Col xs={8}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : bonds.length > 0 ? (
                        bonds.map((bond) => (
                            <div key={bond.request_id} className='bond-card'>
                                <ProposalCard bond={bond} offer={fixture} admin={admin} token={token}/>
                            </div>
                        ))
                    ) : (
                        <p>No bonds found.</p>
                    )}
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BondsModal;