import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { ENDPOINTS } from '../config/endpoints';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const Recommendations = ({ recommendations }) => {
    console.log(recommendations)
    return (
        <div>
            {recommendations.length === 0 ? (
                <p>No recommendations available.</p>
            ) : (
                <div>
                {recommendations.map((rec, index) => (
                    <Container key={index} className='my-3'>
                        <Row className='d-flex justify-content-center'>
                            <Col className='col-6'>
                                <Card className='pt-3 pb-2 bg-light'>
                                    <p><strong>Match:</strong> {rec.home_team_name} vs {rec.away_team_name}</p>
                                    <p><strong>Date:</strong> {new Date(rec.date).toLocaleString()}</p>
                                    <p><strong>League:</strong> {rec.league_id}</p>
                                    <p><strong>Ponderation:</strong> {rec.ponderation.toFixed(2)}</p>
                                </Card>
                            </Col>
                        </Row>
                    </Container>                   
                ))}
                </ div>
            )}
        </div>
    );
};

const CompletedPurchaseWalletPage = () => {
    const location = useLocation();
    const betData = location.state.request_data
    const navigate = useNavigate();
    const [recommendations, setRecommendations] = useState([]);
    const [loadingRecommendations, setLoadingRecommendations] = useState(true);

    const ConfirmPurchaseWallet = async () => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        for (const key of keys) {
            if (key.includes('idToken')) {
                idToken = localStorage.getItem(key);
            }
        }
        try {
            const response = await axios.post(`${ENDPOINTS.API.WALLET_CONFIRM_TRANSACTION}`, betData, {
                headers: { Authorization: `Bearer ${idToken}` },
            });
            console.log(response.data);
            setRecommendations(response.data.recommendations);
            setLoadingRecommendations(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        ConfirmPurchaseWallet();
    }, []);

    return (
        <>
            <Container className='my-3'>
                <Row className='d-flex justify-content-center'>
                    <Col className='col-8'>
                        <Card className='pt-3 pb-3 bg-light'>
                            <h1>Completed Bet Purchase with Wallet</h1>
                            <p>Amount paid with wallet: ${betData.quantity * 1000}</p>
                            <Container>
                                <Button onClick={() => navigate("/fixtures")}>Volver a los fixtures</Button>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <h2>Recommendations</h2>
            {loadingRecommendations ? <Spinner animation="border"></Spinner> : <Recommendations recommendations={recommendations} />}
            
        </>
    )
}

export default CompletedPurchaseWalletPage;