import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import OfferCard from '../components/OfferCard'; // Componente para mostrar la información de la oferta
import './OffersReceivedPage.css';
import { ENDPOINTS } from '../config/endpoints';
import { v4 as uuidv4, validate } from 'uuid';
import { isAdmin } from '../services/isAdmin';

const OffersReceivedPage = () => {
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [offers, setOffers] = useState([]);
    const admin = isAdmin();

    const accept_proposal = async (auction) => {
        try{
            const auction_info ={
                auction_id: auction.auction_id,
                proposal_id: auction.proposal_id,
                fixture_id: auction.fixture_id,
                league_name: auction.league_name,
                round: auction.round,
                result: auction.result,
                quantity: auction.quantity,
                group_id: auction.group_id,
                type: 'acceptance',
            };
            console.log(auction_info);
            const auction_publish = await axios.patch(`${ENDPOINTS.API.AUCTIONS_PATCH}`, auction_info,{
                headers: { Authorization: `Bearer ${token}` },
                params: { admin: admin }
            });
        }
        catch(error){
            console.log(error)
        }
    }

    const reject_proposal = async (auction) => {
        try{
            const auction_info ={
                auction_id: auction.auction_id,
                proposal_id: auction.proposal_id,
                fixture_id: auction.fixture_id,
                league_name: auction.league_name,
                round: auction.round,
                result: auction.result,
                quantity: auction.quantity,
                group_id: auction.group_id,
                type: 'rejection',
            };
            console.log(auction_info);
            const auction_publish = await axios.patch(`${ENDPOINTS.API.AUCTIONS_PATCH}`, auction_info,{
                headers: { Authorization: `Bearer ${token}` },
                params: { admin: admin }
            });
        }
        catch(error){
            console.log(error)
        }
    }
   
    
    // Función para obtener las ofertas recibidas desde el servidor (hardcodeada para pruebas)
    const fetchOffers = async () => {
        try {
            const response = await axios.get(ENDPOINTS.API.AUCTIONS_PROPOSALS,{
                headers: { Authorization: `Bearer ${token}` },
                params: { admin: admin }
            });
            setOffers(response.data);
        } catch (error) {
            console.error('Error fetching auctions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOffers(); // Cargar las ofertas cuando el componente se monta
    }, []);

    return (
        <div>
            <h1>Offers Received</h1>
            <Container>
                {admin ? (
                    <>
                    <Row>
                        <Col></Col>
                        <Col xs={8}>
                            {offers.length > 0 ? offers.map((offer) => (
                                <div key={offer.proposal_id} className="offer-card">
                                    <OfferCard offer={offer} />
                                    <div className="offer-actions">
                                        <Button variant="success" className="mr-2" onClick={() => accept_proposal(offer)}>
                                            Accept
                                        </Button>
                                        <Button variant="danger" onClick={() => reject_proposal(offer)}>
                                            Reject
                                        </Button>
                                    </div>
                                </div>
                            )) : <p>No offers received yet.</p>}
                        </Col>
                        <Col></Col>
                    </Row>
                    </>
                ) : (
                    <p>Not Authorized</p>
                )}
            </Container>
        </div>
    );
};

export default OffersReceivedPage;
