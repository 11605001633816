import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import BondsModal from './BondsModal'; // Asegúrate de que este componente exista y esté importado correctamente
import { ENDPOINTS } from '../config/endpoints';

const AuctionBuyCard = ({ auction, user, token, admin }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const publish_auction_proposal = async (auction_info) => {
        try {
            const auction_publish = await axios.post(`${ENDPOINTS.API.OFFER_AUCTION}`, auction_info);
            console.log('Auction proposal published:', auction_publish.data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>{auction.league_name} - {auction.round}</Card.Title>
                <Card.Text>
                    <strong>Result:</strong> {auction.result} <br />
                    <strong>Quantity:</strong> {auction.quantity} <br />
                    <strong>Group ID:</strong> {auction.group_id} <br />
                </Card.Text>
                <Row className="mt-4">
                    <Col className="text-center">
                        <Button variant="secondary" onClick={() => setIsModalOpen(true)}>
                            View Bonds
                        </Button>
                        <BondsModal user={user} token={token} admin={admin} fixture={auction} show={isModalOpen} handleClose={() => setIsModalOpen(false)} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default AuctionBuyCard;