import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ENDPOINTS } from '../config/endpoints';
import { useNavigate } from 'react-router-dom';


const BuyReservedFixturePage = () => {
    const location = useLocation();
    const [fixture, setFixture] = useState(location.state.fixture);
    const [bond, setBond] = useState(location.state.bond);
    const { user } = useAuthenticator(context => [context.user]);
    const navigate = useNavigate();

    const handleWalletPay = async () => {
        try {
            console.log(bond);
            const data = {
                user_email: user.username,
                request_id: bond.request_id,
            }
            console.log(data);
            const response = await axios.post(`${ENDPOINTS.API.BUY_RESERVED_FIXTURE}`, data);
            navigate('/');
        } catch (error) {
            console.error('Error paying with wallet:', error);
        }
    }

    return (
        <div>
            <Container className='mt-5'>
                <Row className='d-flex justify-content-center'>
                    <Col className='col-7'>
                        <div className="bet-card">
                            <h2>Buying fixture bet on: {fixture.away_team.name} v/s {fixture.home_team.name}</h2>
                            <p>Result: {bond.result}</p>
                            <p>Price: ${bond.quantity * 1000 * 0.9}</p>
                            <Container className='d-flex justify-content-center'>
                                <Col className='d-flex flex-column col-3 align-items-center'>
                                    <Button className='mt-3' onClick={handleWalletPay}>Pay with Wallet</Button>
                                </Col>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BuyReservedFixturePage;