import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ENDPOINTS } from '../config/endpoints';
import { v4 as uuidv4, validate } from 'uuid';


const publish_auction_proposal = async (bond, offer, user, admin, token) => {
    try{
        const auction_info ={
            auction_id: offer.auction_id,
            proposal_id: uuidv4(),
            fixture_id: bond.fixture_id,
            league_name: bond.league_name,
            round: bond.round,
            result: bond.result,
            quantity: bond.quantity,
            group_id: 25,
            type: 'proposal',
            admin: admin,
        };
        console.log(auction_info);
        const auction_publish = await axios.post(`${ENDPOINTS.API.OFFER_AUCTION}`, auction_info,
        {
            headers: { Authorization: `Bearer ${user}` },
        }
        );
    }
    catch(error){
        console.log(error)
    }
}

const ProposalCard = ({ bond, offer, user, admin, token }) => {
    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>{bond.league_name} - {bond.round}</Card.Title>
                <Card.Text>
                    <strong>Result:</strong> {bond.result} <br />
                    <strong>Quantity:</strong> {bond.quantity} <br />
                </Card.Text>
                <Col className="text-center">
                    <Button variant="primary" onClick={() => publish_auction_proposal(bond, offer, user, admin, token)}>
                        Make proposal
                    </Button>
                </Col>
            </Card.Body>
        </Card>
    );
};

export default ProposalCard;
