import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import OfferCard from '../components/OfferCard'; 
import './OffersReceivedPage.css'; 
import { isAdmin } from '../services/isAdmin';

const MyOffersList = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const admin = isAdmin();

    // Función para obtener las ofertas (puedes alternar entre real y hardcoded)
    const fetchOffers = async () => {
        /*
        try {
            const idToken = localStorage.getItem('idToken'); 
            if (!idToken) {
                console.error('Token not found');
                return;
            }
    
            const response = await axios.get('/api/my-offers', {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });
            setOffers(response.data);
        } catch (error) {
            console.error('Error fetching offers:', error);
        } finally {
            setLoading(false);
        }
        */

        // Versión hardcoded para pruebas
        const mockOffers = [
            {
                auction_id: 'a001',
                proposal_id: 'p001',
                fixture_id: 301,
                league_name: 'Bundesliga',
                round: 'Round 5',
                result: '3-1',
                quantity: 20,
                group_id: 12,
                type: 'offer',
            },
            {
                auction_id: 'a002',
                proposal_id: 'p002',
                fixture_id: 302,
                league_name: 'Serie A',
                round: 'Round 10',
                result: '0-1',
                quantity: 25,
                group_id: 14,
                type: 'offer',
            },
        ];
        setOffers(mockOffers);
        setLoading(false);
    };

    // Llamar a fetchOffers al montar el componente
    useEffect(() => {
        fetchOffers();
    }, []);

    // Mostrar un mensaje de carga mientras se obtienen los datos
    if (loading) {
        return <p>Loading offers...</p>;
    }

    // Renderizar las ofertas
    return (
        <Container>
            {admin ? (
                <>
                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        {offers.length > 0 ? (
                            offers.map((offer) => (
                                <div key={offer.proposal_id}>
                                    <OfferCard offer={offer} />
                                </div>
                            ))
                        ) : (
                            <p>No offers found.</p>
                        )}
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center">
                        <Button variant="primary" onClick={() => (window.location.href = '/buy-auctions')}>
                            Make Offers
                        </Button>
                    </Col>
                </Row>
                </>
            ) : (
                <p>Not Authorized</p>
            )}
        </Container>
    );
};

// Componente principal para la página de MyOffers
const MyOffersPage = () => {
    return (
        <div>
            <h1>My Offers</h1>
            <MyOffersList />
        </div>
    );
};

export default MyOffersPage;
