import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL_REQUESTS_RELATION, ENDPOINTS } from '../config/endpoints'; 
import { v4 as uuidv4, validate } from 'uuid';
import { BACKEND_URL_REQUESTS } from '../config/endpoints';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import NoFundsModal from '../components/NoFundsModal';
import NoBondsModal from '../components/NoBondsModal';
import { isAdmin } from '../services/isAdmin';

const BetPage = () => {
  const navigate = useNavigate();
  const [showNoFundsModal, setShowNoFundsModal] = useState(false);
  const [showNoBondsModal, setShowNoBondsModal] = useState(false);
  const { fixtureId } = useParams();
  const [fixture, setFixture] = useState(null);
  const [odds, setOdds] = useState([]);
  const [betAmount, setBetAmount] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBetType, setSelectedBetType] = useState({});
  const [token, setToken] = useState(null);
  const { user } = useAuthenticator(context => [context.user]);
  const admin = isAdmin();

  const handleBetChange = (oddId, amount) => {
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
        console.error('Please enter a valid amount greater than 0.');
        return; 
    }
    setBetAmount(prev => ({
      ...prev,
      [oddId]: amount,
    }));
  };

  const handleBetTypeChange = (id_in_match, value) => {
    console.log(selectedBetType);
    setSelectedBetType(prevState => ({
      ...prevState,
      [id_in_match]: value,
    }));
  };

  const getResultFinal = (result, fixture) => {
    let result_final;
    if (result["1"]) {
      if (result["1"] === "Away") {
        result_final = fixture.away_team.name;
      } else if (result["1"] === "Home") {
        result_final = fixture.home_team.name;
      } else if (result["1"] === "Draw") {
        result_final = "---";
      }
    } else {
      result_final =  fixture.home_team.name;;
    }
    return result_final;
  };
  
  const handleWebpay = async () => {
    if (!betAmount[1]) {
      return;
    }
    const keys = Object.keys(localStorage);
    let idToken = null;
    for (const key of keys) {
      if (key.includes('idToken')) {
        idToken = localStorage.getItem(key);
      }
    }
    const wallet = false
    const request_data = CreateRequestData(1, wallet);
    const relational_data = CreateRelationalData(request_data.request_id);
    try {
      const response = await axios.post(`${ENDPOINTS.API.CREATE_REQUEST}`, {
        request_data: request_data, 
        relational_data: relational_data
      }, {
        headers: {Authorization: `Bearer ${idToken}`}
      });
      console.log('Request created succesfully', response);
    } catch (error) {
      console.error('Error creating request', error);
    }
    try {
      const response = await axios.post(`${ENDPOINTS.API.WEBPAY_CREATE_TRANSACTION}`, {
        amount: request_data.quantity * 1000,
        user: user.username,
        request_id: request_data.request_id,
      }, {
        headers: {Authorization: `Bearer ${idToken}`}
      })
      console.log('Webpay transaction created succesfully', response);
    } catch {
      console.error('Error creating Webpay transaction');
    }
    navigate('/confirm-bet/webpay',
      { state: { request_data: request_data } }
    );
  }

  const handleWalletPay = async () => {
    if (!betAmount[1]) {
      return;
    }
    const keys = Object.keys(localStorage);
    let idToken = null;
    for (const key of keys) {
      if (key.includes('idToken')) {
        idToken = localStorage.getItem(key);
      }
    }
    const wallet = true;
    const request_data = CreateRequestData(1, wallet);
    const relational_data = CreateRelationalData(request_data.request_id);
    try {
      const response = await axios.post(`${ENDPOINTS.API.CREATE_REQUEST}`, {
        request_data: request_data, 
        relational_data: relational_data
      }, {
        headers: {Authorization: `Bearer ${idToken}`}
      }
    );
      console.log('Request created succesfully', response);
      navigate('/completed-purchase/wallet',
        { state: { request_data: request_data } }
      )
    } catch (error) {
      console.error('Error creating request', error);
      const message = error.response.data.message;
      console.log(message);
      if (message === 'Not enough bonds available')
        setShowNoBondsModal(true);
      if (message === 'Not enough funds')
        setShowNoFundsModal(true);
    }
  }

  const CreateRelationalData = (request_id) => {
    const relationData = {
      email: user.username,
      request_id: request_id,
    }
    return relationData;
  }

  const CreateRequestData = (oddId, wallet) => {
    const request_id = uuidv4()
    let seller = '0'
    if (admin)
    {
      seller = '25'
    }
    const request_data = {
      request_id: request_id,
      group_id: '25',
      fixture_id: fixture.id,
      league_name: fixture.league.name,
      round: fixture.league.round,
      date: fixture.date,
      result: getResultFinal(selectedBetType, fixture),
      deposit_token: "",
      datetime: new Date().toISOString(),
      quantity: betAmount[oddId],
      wallet: wallet,
      seller: seller,
    }
    return request_data;
  }

  const fetchFixtureData = async () => {
    try {
      const keys = Object.keys(localStorage);
      let idToken = null;
      for (const key of keys) {
        if (key.includes('idToken')) {
          idToken = localStorage.getItem(key);
        }
      }
      const responseFixture = await axios.get(ENDPOINTS.API.FIXTURES_BY_ID(fixtureId));
      setFixture(responseFixture.data);
      const response = await axios.get(`${ENDPOINTS.API.ODDS}/${fixtureId}/`,
        {
          headers: {Authorization: `Bearer ${idToken}`},
        }
      );
      const odds = response.data.odds
      const last_odds  = odds.slice(-1);
      setOdds(last_odds);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching fixture odds:', error);
      setError('Error fetching odds. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFixtureData();
  }, []);

  if (loading) return <p>Loading odds...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container className='mt-5'>
      <Row className='d-flex justify-content-center'>
        <Col className='col-7'>
          <div className="bet-card">
            <h2>Betting on: {fixture.away_team.name} v/s {fixture.home_team.name}</h2>

            <div className="odds-container">
              {odds.map((odd) => (
                <div key={odd.id_in_match} className="odd-item">
                  <h3>{odd.name}</h3>
                  <div className="odds-values">
                    {odd.values.map((value, index) => (
                      <span key={index} className="odd-value">
                        {value.value}: {value.odd}
                      </span>
                    ))}
                  </div>
                  
                  <label>
                    Select Option:
                    <select onChange={(e) => handleBetTypeChange(odd.id_in_match, e.target.value)}>
                      {odd.values.map((value, index) => (
                        <option key={index} value={value.value}>
                          {value.value}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label>
                    Amount of bonds:
                    <input
                      type="number"
                      value={betAmount[odd.id_in_match] || ''}
                      onChange={(e) => handleBetChange(odd.id_in_match, e.target.value)}
                    />
                  </label>
                </div>
              ))}
            </div>
            <Container className='d-flex justify-content-center'>
              <Col className='d-flex flex-column col-3 align-items-center'>
                <Button onClick={handleWebpay}>Pay with Webpay</Button>
                <Button className='mt-3' onClick={handleWalletPay}>Pay with Wallet</Button>
              </Col>
            </Container>
            
          </div>
        </Col>
      </Row>
      <NoFundsModal show={showNoFundsModal} />
      <NoBondsModal show={showNoBondsModal} />
    </Container>
    
  );
};

export default BetPage;
