export function isAdmin() {
    try {
        const userPoolId = '6isd0lobgtkne1p9ft7vgs8upg';
        const clientId = localStorage.getItem(`CognitoIdentityServiceProvider.${userPoolId}.LastAuthUser`);

        const storageKey = `CognitoIdentityServiceProvider.${userPoolId}.${clientId}.idToken`;
        const idToken = localStorage.getItem(storageKey);

        if (!idToken) {
            console.error('idToken not found in localStorage');
            return false;
        }

        const tokenParts = idToken.split('.');
        const encodedPayload = tokenParts[1];
        const rawPayload = atob(encodedPayload);
        const payload = JSON.parse(rawPayload);

        try {
            const userCognito = payload['cognito:groups'][0];
            return userCognito === 'Admin';
        } catch (error) {
            console.log('Error, user does not have cognito:group');
            return false;
        }
    } catch (error) {
        console.log('No user logged in');
        return false;
    }
}