import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import AuctionBuyCard from '../components/AuctionBuyCard'; 
import './AuctionsPage.css';
import { ENDPOINTS } from '../config/endpoints';
import { v4 as uuidv4, validate } from 'uuid';
import { isAdmin } from '../services/isAdmin';

const BuyAuctionsPage = () => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const admin = isAdmin();

    // Función real para obtener las subastas disponibles
    const fetchAuctions = async () => {
        try {
            const response = await axios.get(ENDPOINTS.API.OFFER_TO_BUY,{
                headers: { Authorization: `Bearer ${token}`,
             },
             params: { admin: admin }
            });
            setAuctions(response.data);
        } catch (error) {
            console.error('Error fetching auctions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Comentar y descomentar según sea necesario para probar
        // fetchAuctions(); // Para la solicitud real
        fetchAuctions(); // Para pruebas con datos fijos
    }, []);

    useEffect(() => {
        const keys = Object.keys(localStorage);
        let idToken = null;
        let user = null;
        
        for (const key of keys) {
          if (key.includes('idToken')) {
            idToken = localStorage.getItem(key);
          }
          if (key.includes('LastAuthUser')) {
            user = localStorage.getItem(key);
          }
        }
      
        if (idToken) {
          setToken(idToken);
          setUser(user);
        } else {
          console.error('No tokens found in localStorage');
        }
      }, []);

    // Llamar a fetchAuctions al montar el componente
    useEffect(() => {
        if (user) {
           fetchAuctions(); 
        }
    }, [user]);

    return (
        <div>
            <h1>Make proposals</h1>
            <Container>
                {admin ? (
                    <>
                    <Row>
                        <Col></Col>
                        <Col xs={8}>
                           {auctions.length > 0 ? auctions.map((auction) => (
                               <div key={auction.auction_id} className="auction-card">
                                   <AuctionBuyCard auction={auction} user={user} token={token} admin={admin}/>
                               </div>
                           )) : <p>No auctions available to participate in.</p>}
                        </Col>
                        <Col></Col>
                    </Row>
                    </>
                ) : (
                    <p>Not Authorized</p>
                )}
            </Container>
        </div>
    );
};

export default BuyAuctionsPage;
