import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ENDPOINTS } from '../config/endpoints';
import { v4 as uuidv4, validate } from 'uuid';


const publish_auction = async (auction, admin) => {
    try{
        const auction_info ={
            auction_id: uuidv4(),
            proposal_id: '',
            fixture_id: auction.fixture_id,
            league_name: auction.league_name,
            round: auction.round,
            result: auction.result,
            quantity: auction.quantity,
            group_id: 25,
            type: 'offer',
            admin: admin
        };
        console.log(auction_info);
        const auction_publish = await axios.post(`${ENDPOINTS.API.OFFER_AUCTION}`, auction_info);
    }
    catch(error){
        console.log(error)
    }
}

const AuctionCard = ({ auction, admin }) => {
    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>{auction.league_name} - {auction.round}</Card.Title>
                <Card.Text>
                    <strong>Result:</strong> {auction.result} <br />
                    <strong>Quantity:</strong> {auction.quantity} <br />
                </Card.Text>
                <Col className="text-center">
                    <Button variant="primary" onClick={() => publish_auction(auction, admin)}>
                        Make offer
                    </Button>
                </Col>
            </Card.Body>
        </Card>
    );
};

export default AuctionCard;
