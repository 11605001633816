import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isAdmin } from '../services/isAdmin';

const AppNavbar = ({ isAuthenticated, logout }) => {
  const { user, signOut } = useAuthenticator(context => [context.user]);
  const admin = isAdmin();
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">CoolGoat App</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/fixtures">
              <Nav.Link>Fixtures</Nav.Link>
            </LinkContainer>
            {user && (
              <>
              {!admin && (
                <>
                  <LinkContainer to="/reserved-fixtures">
                    <Nav.Link>Reserved Fixtures</Nav.Link>
                  </LinkContainer>
                </>
              )}
                <LinkContainer to="/requests">
                  <Nav.Link>Requests</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/wallet">
                  <Nav.Link>Wallet</Nav.Link>
                </LinkContainer>
                {admin && (
                  <>
                  <LinkContainer to="/auctions">
                    <Nav.Link>My Auctions</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/buy-auctions">
                    <Nav.Link>Buy Auctions</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/offers-received">
                    <Nav.Link>Offers Received</Nav.Link>
                  </LinkContainer>
                  </>
                )}
              </>
            )}
            </Nav>
          <Nav className="ml-auto">
            {user && (
              <Navbar.Text className={admin ? 'text-danger' : ''} >Welcome back {user.username}!</Navbar.Text>
            )}
            {user ? (
              <NavDropdown className='ms-4' title="Profile" id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Profile Settings</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/wallet">
                  <NavDropdown.Item>Wallet</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={signOut}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
